const firebaseConfig = {
  apiKey: 'AIzaSyCQomHoCzXRNhCt_rnY0Hj-ZWfGFAOqbkc',
  authDomain: 'cheshta-gupta.firebaseapp.com',
  projectId: 'cheshta-gupta',
  storageBucket: 'cheshta-gupta.appspot.com',
  messagingSenderId: '812581400149',
  appId: '1:812581400149:web:6951d390e04369e9cdaedc',
  measurementId: 'G-3TLL1DH94T',
};

export default firebaseConfig;
