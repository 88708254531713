import Avatar from './Avatar.png';
import Paint from './Paint.png';
import Blob from './Blob.png';
import Brand from './Brand.svg';
import Laptop from './Laptop.png';
import Hero from './Hero.jpg';
import Error from './error.jpg';

const images: Record<string, string> = {
  Avatar,
  Paint,
  Blob,
  Hero,
  Brand,
  Laptop,
  Error,
};

export default images;
