import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import ReactDOM from 'react-dom/client';
import App from './App';
import './reset.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import firebaseConfig from './firebaseConfig';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
