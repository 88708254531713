import React, { FunctionComponent } from 'react';
import './index.css';

interface ButtonProps {
  variant?: string;
  label: string;
  disabled?: boolean;
  onClick: () => void;
}

const Button: FunctionComponent<ButtonProps> = ({
  variant,
  label,
  disabled,
  onClick,
}) => {
  return (
    <button
      disabled={disabled}
      type="button"
      className={`btn btn-${disabled ? 'disabled' : variant || 'primary'} `}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

Button.defaultProps = {
  variant: '',
  disabled: false,
};

export default React.memo(Button);
