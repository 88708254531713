import { useState } from 'react';
import './index.css';
import images from '../../images/index';
import Button from '../../Components/Button';

const Header: React.FunctionComponent = (): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const openClass = isMenuOpen ? 'open' : '';

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  return (
    <header className="header container">
      <nav className="nav">
        <a href="#home">
          <img src={images.Brand} alt="Cheshta Brand" className="nav-brand" />
        </a>
        <button
          type="button"
          className={`nav-icon ${openClass}`}
          onClick={() => toggleMenu()}
        >
          <span />
          <span />
          <span />
          <span />
        </button>
        <div className={`menu-container ${openClass}`}>
          <a href="#home">
            <img src={images.Brand} alt="Cheshta Brand" className="nav-brand" />
          </a>

          <ul className="nav-menu">
            <li className="nav-item">
              <a href="#about">About</a>
            </li>
            <li className="nav-item">
              <a href="#work">Work</a>
            </li>
            <li className="nav-item">
              <a href="#art">Art</a>
            </li>
            <li className="nav-item">
              <Button label="Get in Touch" onClick={() => {}} />
            </li>
          </ul>
        </div>
      </nav>
      <p className="name">Cheshta Gupta</p>
      <p className="desc">Developer &amp; Artist</p>
      <div className="image-group">
        <img src={images.Laptop} alt="Laptop Code" className="laptop" />
        <img src={images.Avatar} alt="Girl" className="avatar" />
        <img src={images.Paint} alt="Paint Palette" className="paint" />
      </div>
    </header>
  );
};

export default Header;
